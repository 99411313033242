import styled from '@emotion/styled';
import { DOM_STATE } from 'components/constants';
import Link from 'components/GatsbyLink';
import { themev2 } from 'theme-v2';
import {
  MEGA_MENU_HEIGHT_MEDIA_QUERY,
  MENU_CONTENT_CATEGORY_HEIGHT,
  MENU_CONTENT_CATEGORY_WIDTH,
  MENU_CONTENT_HEIGHT,
  MENU_CONTENT_HEIGHT_ADDITIONAL,
} from '../constants';
import { LinkButton } from '@smartproxy-web/features';
import { StyledButton, theme } from '@smartproxy-web/ui';
import { richTextParagraphElements } from 'constants/common';

export const CategoryItemTitle = styled.p({
  fontSize: themev2.fonts.size.p_medium,
  lineHeight: themev2.fonts.lineHeight.p_small,
  fontWeight: 400,
  color: themev2.colors.white.default,
  marginRight: '4px',
  fontFamily: themev2.fonts.family.additional,
});

export const CategoriesItemsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'start',
  width: 'auto',
  maxWidth: MENU_CONTENT_CATEGORY_WIDTH,
  padding: '0 36px',
  marginBottom: '-16px',
  maxHeight: MENU_CONTENT_HEIGHT,
  overflowY: 'auto',
  overflowX: 'hidden',
  minWidth: '250px',
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: themev2.colors.white.transparent,
  },
  [MEGA_MENU_HEIGHT_MEDIA_QUERY]: {
    maxHeight: MENU_CONTENT_HEIGHT_ADDITIONAL,
  },
});

export const CategoryContentContainer = styled.div({
  display: 'inline-flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  maxHeight: MENU_CONTENT_CATEGORY_HEIGHT,
  alignContent: 'flex-start',
  columnGap: '15px',
  width: '100%',
});

export const CategoryItemContainer = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'hasPrice',
})<{ hasPrice: boolean }>((hasPrice) => ({
  width: '100%',
  display: 'flex',
  flexDirection: hasPrice ? 'row' : 'column',
  justifyContent: 'flex-start',
  padding: `8px ${themev2.spacings.XS}`,
  marginBottom: hasPrice ? '4px' : '8px',
  borderRadius: '4px',
  ':hover': {
    background: themev2.colors.white.transparent,
  },
  [DOM_STATE.ACTIVE]: {
    background: themev2.colors.white.transparent,
  },
}));

export const CategoriesItemsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',

  [String(CategoriesItemsContainer)]: {
    borderRight: '1px solid rgba(255, 255, 255, 0.1)',
    ':last-of-type': {
      borderRight: 'none',
    },
    ':first-of-type': {
      paddingLeft: 0,
      marginLeft: `-${themev2.spacings.XS}`,
    },
  },

  [String(CategoryItemContainer)]: {
    flexDirection: 'row',
  },
});

export const CategoryTitle = styled.p({
  fontFamily: themev2.fonts.family.additional,
  fontSize: themev2.fonts.size.p_xsmall,
  lineHeight: themev2.fonts.lineHeight.p_xsmall,
  fontWeight: 400,
  color: themev2.colors.neutral.N40,
  paddingLeft: themev2.spacings.XS,
  marginBottom: '16px',
  letterSpacing: 1.2,
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginLeft: '6px',
  },
  ':hover': {
    svg: {
      marginLeft: '10px',
      transition: '0.16s ease-in-out',
    },
  },
});

export const CategoryItemWrapper = styled.div({
  display: 'flex',
});

export const CategoryItemTitleContainer = styled.div<{
  color: string;
  hasPrice: boolean;
}>(({ color, hasPrice }) => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  width: hasPrice ? '220px' : 'auto',
  marginBottom: '4px',
  span: {
    marginBottom: '4px',
    color: color === 'gray' ? themev2.colors.neutral.N00 : 'default',
  },
}));

export const CategoryItemDescription = styled.div({
  maxWidth: '200px',
  fontSize: themev2.fonts.size.p_xsmall,
  lineHeight: themev2.fonts.lineHeight.p_xsmall,
  fontWeight: 300,
  color: themev2.colors.gray.S300,
  marginTop: '4px',
  fontFamily: themev2.fonts.family.additional,
});

export const CategoryPricing = styled.div({
  display: 'flex',
  paddingLeft: '16px',
  marginBottom: '4px',
  alignItems: 'center',
  span: {
    color: themev2.colors.gray.S300,
    fontSize: themev2.fonts.size.p_xsmall,
    fontWeight: 400,
    lineHeight: themev2.fonts.lineHeight.p_xsmall,
    fontFamily: themev2.fonts.family.additional,
    paddingRight: '0.5ch',
  },
  div: {
    color: themev2.colors.white.default,
    fontWeight: 400,
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: themev2.fonts.lineHeight.p_small,
    fontFamily: themev2.fonts.family.additional,
    span: {
      fontSize: themev2.fonts.size.p_xsmall,
      fontFamily: themev2.fonts.family.additional,
      paddingLeft: '0.5ch',
    },
  },
});

export const CategoryItemLinkBlock = styled.div({
  marginBottom: '48px',
  ':last-of-type': {
    marginBottom: 0,
  },
  div: {
    width: '100%',
    marginLeft: '-4px',
    wordBreak: 'break-all',
    a: {
      color: themev2.colors.neutral.N00,
      fontSize: themev2.fonts.size.p_xsmall,
      lineHeight: '26px',
      padding: '0 4px',
      borderRadius: '4px',
      display: 'inline-block',
      ':hover': {
        background: themev2.colors.white.transparent,
      },
    },
  },
});

export const VisibilityContainer = styled.div<{
  selected: boolean;
}>(({ selected }) => ({
  visibility: selected ? 'visible' : 'hidden',
  opacity: selected ? 1 : 0,
  padding: '48px 40px',
  width: '100%',
  height: '100%',
  display: 'flex',
  transition: 'opacity 0.3s ease',
  transitionDelay: '0.15s',
  position: 'relative',
  zIndex: 10,
  maxWidth: '1505px',
  margin: '0 auto',
}));

export const CategoryBannerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacings.S,
  position: 'relative',
  borderColor: 'transparent',

  ':after': {
    borderRadius: '12px',
    position: 'absolute',
    content: '""',
    inset: -1,
    background: theme.colors.gradient.default,
    padding: 1,
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
});

export const StyledLinkButtonContainer = styled(LinkButton)({
  zIndex: 1,
  marginTop: theme.spacings.S,
  [String(StyledButton)]: {
    paddingRight: '16px',
    paddingLeft: '16px',
    height: '32px',
    span: {
      fontSize: theme.fonts.size.p_small,
      lineHeight: '28px',
    },
  },
});

export const StyledBannerTitle = styled.div({
  fontSize: theme.fonts.size.p_large,
  color: theme.colors.neutral.N00,
  fontWeight: 700,
  fontFamily: theme.fonts.family.additional,
  lineHeight: '20px',
});

export const StyledBannerDescription = styled.div({
  zIndex: 1,
  color: theme.colors.neutral.N40,
  marginTop: theme.spacings.XS,
  [richTextParagraphElements]: {
    fontSize: theme.fonts.size.p_xsmall,
  },
});
